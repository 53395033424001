import React from "react"
import "./scss/index.scss"

const Features = ({ equipment, title, short, wrap}) => {
    let equipmentData = equipment;

    if(short) {
        equipmentData = equipment.slice(0, 3);
    }

    return (
        <>
            <h3 className="equipment-title">{title}</h3>
            <ul className={`equipment-list ${wrap ? 'd-flex flex-lg-wrap flex-column wrap' : ''}`}>
                {equipmentData.map((item, i) => (
                    <li key={i}>
                        <div className="equipment-wrapper">
                            {item.icon ?
                                <img className="equipment-image" alt={item.title} src={item.icon.localFile.publicURL}/>
                            :
                                <span></span>
                            }
                        </div>
                        <span>
                            {item.title}
                        </span>
                    </li>
                ))}
            </ul>
        </>
    )
}

export default Features