import React from "react";
import "./scss/index.scss";
import Featured from "./Featured";
import Single from "./Single";

const News = ({newsArray, featuredArray}) => {
    let news;
    let featured;
    
    if (featuredArray) {
        featured = featuredArray.slice(0, 1);
        news = newsArray
    } else {
        featured = newsArray.slice(0, 1);
        news = newsArray.shift();
    }

    return (
        <div className="row news">
            <div className="col-lg-6">
                <Featured featured={featured[0]}/>
            </div>
            <div className="col-12 col-lg-6 d-flex flex-column">
                {news.slice(0, 3).map((single, i) => (
                    <Single key={i} single={single}/>
                    ))}
            </div>
        </div>
    )
}
export default News