import React, {useState, useEffect} from "react";
import { Link } from 'gatsby';
import Slider from "react-slick";
import 'bootstrap/js/dist/modal';

import Features from "./Features"
import House from "../house/House";

import "./scss/index.scss"

const Equipment = ({houses}) => {
    const [nav1, setNav1] = useState(null)
    const [nav2, setNav2] = useState(null)
    let slider1 = []
    let slider2 = []

    const settingsHouses = {
        centerMode: true,
        centerPadding: '40%',
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        focusOnSelect: true,
        waitForAnimate: false,
        cssEase: 'ease-out',
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: '10px'
                }
            },
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: '34%'
                }
            },
        ]
    };

    const settingsData = {
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        speed: 500,
        arrows: false,
        swipeToSlide: false,
        draggable: false,
        adaptiveHeight: true
    };

    useEffect(() => {
        setNav1(slider1)
        setNav2(slider2)
    }, [slider1, slider2])

    return (
        <div className="equipment">
            <section className="equipment-houses">
                <div className="equipment-houses-container">
                    <Slider {...settingsHouses} asNavFor={nav2} ref={slider => (slider1 = slider)}>
                        {houses.map((houseItem, i) => {
                            return <House key={i} houseData={houseItem} />
                        })}
                    </Slider>
                </div>
            </section>
            <Slider {...settingsData} asNavFor={nav1} ref={slider => (slider2 = slider)}>
                {houses.map((houseData, i) => {
                    return (
                        <div key={i}>
                            <section className="container equipment-rooms d-none d-lg-block">
                                <div className="row justify-content-center">
                                    {houseData.node.acf.rooms.map((room, i) => {
                                        return (
                                            <div className="col-auto" key={i}>
                                                <div className="rooms-wrapper">
                                                    <div className="rooms-image">
                                                        <img src={room.icon.localFile.publicURL} alt={room.description}/>
                                                    </div>
                                                    <h3 className="rooms-title">{room.title}</h3>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </section>
                            <section className="equipment-features container">
                                <div className="row">
                                    {houseData.node.acf.availability === true ?
                                        <>
                                            <div className="equipment-borders col-4 d-none d-lg-block">
                                                <Features title="Wyposażenie standardowe" short equipment={houseData.node.acf.equipment_standard}/>
                                            </div>
                                            <div className="equipment-borders col-4 d-none d-lg-block">
                                                <Features title="Wyposażenie opcjonalne" short equipment={houseData.node.acf.equipment_optional}/>
                                            </div>
                                            <div className="col-12 col-lg-4">
                                                <div className="equipment-buttons">
                                                    <Link to={`${houseData.node.link}#calendar`} className="btn btn-primary">Sprawdź termin</Link>
                                                    <Link to={houseData.node.link} className="btn btn-outline-primary">Więcej o miejscu</Link>
                                                    <Link to='/pytaj-o-co-chcesz/' className="btn btn-secondary btn-sm">Pytaj o co chcesz</Link>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                        <div className="equipment-justify col-12 col-lg-8">
                                            <h3 className="equipment-justify-color-big">
                                                <span className="equipment-justify-color-small">Zapraszamy Gości od:</span><br/>
                                                {houseData.node.acf.availability_date}
                                            </h3>
                                            <p className="equipment-justify-small d-none d-lg-block">Sprawdź inne domki</p>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <div className="equipment-buttons">
                                                <Link to='/sprawdz-termin/' className="btn btn-primary">Sprawdź termin</Link>
                                                <Link to={houseData.node.link} className="btn btn-outline-primary">Więcej o miejscu</Link>
                                                <Link to='/pytaj-o-co-chcesz/' className="btn btn-secondary btn-sm">Pytaj o co chcesz</Link>
                                            </div>
                                        </div>
                                        </>
                                    }
                                </div>
                            </section>
                            <div className="equipment-more d-none d-lg-block">
                                <button type="button" className="btn" data-bs-toggle="modal" data-bs-target={'#modal-' + houseData.node.slug}>
                                    pokaż więcej
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12.25" height="12.25" viewBox="0 0 12.25 12.25">
                                        <path id="Path_628" data-name="Path 628" d="M11.375-6.562a.841.841,0,0,1,.615.26.841.841,0,0,1,.26.615v.875a.841.841,0,0,1-.26.615.841.841,0,0,1-.615.26H7.437V0a.841.841,0,0,1-.26.615.841.841,0,0,1-.615.26H5.687a.841.841,0,0,1-.615-.26A.841.841,0,0,1,4.812,0V-3.937H.875A.841.841,0,0,1,.26-4.2.841.841,0,0,1,0-4.812v-.875A.841.841,0,0,1,.26-6.3a.841.841,0,0,1,.615-.26H4.812V-10.5a.841.841,0,0,1,.26-.615.841.841,0,0,1,.615-.26h.875a.841.841,0,0,1,.615.26.841.841,0,0,1,.26.615v3.937Z" transform="translate(0 11.375)" fill="#a70040"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    )})}
            </Slider>
            <div className="equipment-modals">
                {houses.map((houseData, i) => {
                    return (
                        <div className="modal fade" key={i} id={'modal-' + houseData.node.slug} tabIndex="-1" aria-labelledby={'modal-header-' + houseData.node.slug} aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className={'modal-header modal-header-' + houseData.node.slug} >
                                        <House key={i} houseData={houseData} />
                                        <button type="button" data-bs-dismiss="modal" aria-label="Close">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="37.117" height="37.117" viewBox="0 0 37.117 37.117">
                                                <path id="Path_918" data-name="Path 918" d="M958.594,195.7l-3.739-3.739-14.818,14.818h0l0,0,0,0h0l-14.818-14.818-3.739,3.739,14.82,14.82-14.82,14.82,3.739,3.739,14.818-14.818h0l0,0,0,0h0l14.818,14.818,3.739-3.739-14.82-14.82Z" transform="translate(-921.477 -191.957)" fill="#a70040"/>
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="modal-equipment">
                                            {houseData.node.acf.availability ? '' : houseData.node.acf.aviability_info}
                                            <div className="equipment-borders col-8">
                                                <Features title="Wyposażenie standardowe" equipment={houseData.node.acf.equipment_standard}/>
                                            </div>
                                            <div className="equipment-borders col-4">
                                                <Features title="Wyposażenie opcjonalne" equipment={houseData.node.acf.equipment_optional}/>
                                            </div>
                                        </div>
                                        <div className="modal-buttons">
                                            <a href={houseData.node.link} className="btn btn-outline-primary">Więcej o tym</a>
                                            {houseData.node.acf.availability ?
                                                <a href='/sprawdz-termin/' className="btn btn-primary">Sprawdź termin</a>
                                                :
                                                <a href='/sprawdz-termin/' className="btn btn-dark disabled">Rezerwuj</a>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )})}
            </div>
        </div>
    )
}
export default Equipment