import React, { useState } from "react"
import { Link as SmoothScroll } from "react-scroll"
import { useInterval } from "./useInterval"
import PropTypes from "prop-types"

import Slide from "../slide/Slide"
import ReactHtmlParser from "react-html-parser"
import logo from "../../images/logo-full.svg"

import "./scss/index.scss"

const Slider = ({ slides, heading }) => {
  const [state, setState] = useState({
    slides,
    activeIndex: 0,
    autoSlide: true,
    interval: 7500,
  })

  // By Dan Abramov overreacted.io
  useInterval(
    () => {
      setState(prev => ({
        ...prev,
        activeIndex: (prev.activeIndex + 1) % prev.slides.length,
      }))
    },
    state.autoSlide ? state.interval : null
  )

  return (
    <div className="slider">
      {slides.map((slide, i) => (
        <Slide key={i} slide={slide.image} active={state.activeIndex === i} />
      ))}
      <div className="slider-container">
        <img
          src={logo}
          className="slider-image"
          style={{ zIndex: 99 }}
          alt="Site Logo"
        />
        <h1 className="slider-title">{ReactHtmlParser(heading)}</h1>
        <SmoothScroll to="warunki" smooth={true} offset={-55} duration={500} className="btn btn-primary mt-3">
          Odwiedź nas
        </SmoothScroll>
      </div>
    </div>
  )
}

Slider.propTypes = {
  slides: PropTypes.array.isRequired,
  heading: PropTypes.string.isRequired,
}

export default Slider
