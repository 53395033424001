import React from 'react';
import Img from "gatsby-image";
import PropTypes from "prop-types";

import './scss/index.scss';

const Slide = ({ slide, active }) => (
    <div className={`slide ${active ? 'active' : ''}`}>
        <Img loading="eager" className="slide-image" fluid={slide.localFile.childImageSharp.fluid} />
    </div>
)

Slide.propTypes = {
    slide: PropTypes.object.isRequired,
    active: PropTypes.bool.isRequired,
}

export default Slide;
