import React from "react";
import SimpleReactLightbox from "simple-react-lightbox";
import { Element } from 'react-scroll'

import Layout from "../components/layout";
import PageHero from "../components/pageHero/PageHero";
import HeroSlider from "../components/slider/Slider";
import Section from "../components/section/Section";
import News from "../components/news/News";
import Gallery from "../components/gallery/Gallery";
import Places from "../components/places/Places";
import Equipment from "../components/equipment/Equipment";

const IndexPage = ({data}) => {
    const frontPageAcfData = data.frontPageData.edges[0].node.acf;
    return (
        <Layout homepage staticMap={data.staticMap.mapUrl}>
            <PageHero fullwidth fullheight homepage>
                <div className="row">
                    <HeroSlider heading={frontPageAcfData.cover_heading} slides={frontPageAcfData.cover_background}/>
                </div>
            </PageHero>
            <Element name="#warunki">
                <Section id="warunki" header="Warunki i wyposażenie">
                    <Equipment houses={data.frontPageHouses.edges}/>
                </Section>
            </Element>
            <Element name="#aktualnosci">
                <Section id="aktualnosci" header="Aktualności" hideHeader className="section-dark">
                    <News newsArray={data.newsPosts.edges} featuredArray={data.newsFeaturedPosts.edges}/>
                </Section>
            </Element>
            <Element name="#wnetrza">
                <Section id="wnetrza" header="Wnętrza i otoczenie">
                    <SimpleReactLightbox>
                        <Gallery photos={frontPageAcfData.home_gallery}/>
                    </SimpleReactLightbox>
                </Section>
            </Element>
            <Element name="#atrakcje">
                <Section id="atrakcje" header="Atrakcje w pobliżu">
                    <Places sliderData={data.frontPagePlaces.edges}/>
                </Section>
            </Element>
        </Layout>
    )
}

export const pageData = graphql`
    query pageDataQuery {
        frontPageData: allWordpressPage(filter: {wordpress_id: {eq: 5}}) {
            edges {
                node {
                    acf {
                        cover_heading
                        cover_background {
                            image {
                                localFile {
                                    childImageSharp {
                                        fluid(quality: 40, maxWidth: 1920) {
                                            ...GatsbyImageSharpFluid_withWebp
                                        }
                                    }
                                }
                            }
                        }
                        home_gallery {
                            alt_text
                            localFile {
                                childImageSharp {
                                    fluid(quality: 60, maxWidth: 1280) {
                                        aspectRatio
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                    fixed(width: 200) {
                                        ...GatsbyImageSharpFixed_withWebp
                                    }
                                    original {
                                        src
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        frontPageHouses: allWordpressWpHouses(sort: {fields: date, order: DESC})  {
            edges {
                node {
                    link
                    acf {
                        availability
                        availability_date
                        availability_info
                        people
                        space
                        rooms {
                            title
                            icon {
                                localFile {
                                    publicURL
                                }
                            }
                            description
                        }
                        equipment_optional {
                            title
                            icon {
                                localFile {
                                    publicURL
                                }
                            }
                        }
                        equipment_standard {
                            title
                            icon {
                                localFile {
                                    publicURL
                                }
                            }
                        }
                        icon {
                            localFile {
                                publicURL
                            }
                        }
                    }
                    slug
                    title
                    wordpress_id
                }
            }
        }
        frontPagePlaces: allWordpressWpPlaces {
            edges {
              node {
                slug
                title
                link
                acf {
                  distance
                  transportation
                  icon {
                    localFile {
                      publicURL
                    }
                  }
                }
              }
            }
        }
        newsPosts: allWordpressPost( filter: {sticky: {eq: false}} ) {
            edges {
                node {
                    slug
                    wordpress_id
                    title
                    link
                    date(formatString: "Y.MM.D")
                    sticky
                    excerpt
                    featured_media {
                        localFile {
                            childImageSharp {
                                fluid(quality: 100, maxWidth: 1280) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
        newsFeaturedPosts: allWordpressPost( filter: {sticky: {eq: true}} ) {
            edges {
                node {
                    slug
                    wordpress_id
                    title
                    date(formatString: "Y.MM.D")
                    sticky
                    link
                    excerpt
                    featured_media {
                        localFile {
                            childImageSharp {
                                fluid(quality: 100, maxWidth: 1280) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
        staticMap { 
            mapUrl
        }
    }
`


export default IndexPage
