import React from 'react';
import PropTypes from 'prop-types'
import Img from 'gatsby-image';
import { SRLWrapper } from 'simple-react-lightbox';

import './scss/index.scss'

const Gallery = ({photos}) => {
    const gridSchema = [8, 4, 4, 8, 6, 6, 4, 4, 4, 12]
    const lightboxOptions = {
        buttons: {
            showFullscreenButton: false,
            showThumbnailsButton: false,
            showDownloadButton: false,
            showAutoplayButton: false
        },
        caption: {
            showCaption: false
        }
    }

    return (
        <>
            <SRLWrapper options={lightboxOptions}>
                <div className='container gallery d-none d-lg-block'>
                        <div className='row'>
                            {photos.map((image, i) => {
                                return (
                                    <div key={i} className={'col-' + gridSchema[i % gridSchema.length] + ' gallery-image'}>
                                        <a href={image.localFile.childImageSharp.original.src}>
                                            <Img fluid={image.localFile.childImageSharp.fluid} alt={image.alt_text}/>
                                        </a>
                                    </div>
                                )
                            })}
                        </div>
                </div>
            </SRLWrapper>
            <div className="gallery-mobile d-block d-lg-none">
                <div className="row">
                    {photos.map((image, i) => {
                        return (
                            <div className="gallery-mobile-photo" key={i}>
                                <Img fluid={image.localFile.childImageSharp.fluid} alt={image.alt_text}/>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
   )
}

Gallery.propTypes = {
    photos: PropTypes.array.isRequired,
}


export default Gallery;